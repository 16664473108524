body {
  margin: 0;
}
.ant-layout {
  background-color: white;
}

.site-layout-background {
  background-color: white;
  padding: 1rem 4rem 4rem 2rem;
}

.responsive-image {
  width: 100%;
}

.ellipsis,
.ant-list-item .ant-typography {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-card-type-inner .ant-card-head {
  background: #69aded;
  color: white;
}
.ant-card-type-inner .ant-card-head-title {
  font-size: 1.15em;
}

.ant-card-extra a {
  color: white;
}
