.ant-layout-header {
  background-color: white !important;
}
.site-card-wrapper {
  margin: 24px 0;
  padding: 0 24px;
}

.ant-typography a,
a.ant-typography {
  color: black !important;
}

.ant-typography a:hover,
a:hover.ant-typography {
  color: #1890ff !important;
}

.carousel {
  max-height: 380px;
}
